.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-y {
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.scroll-x {
  overflow-x: auto;
}

.text-muted {
  color: #88949f !important;
}

.country-select__control {
  border: none !important;
  padding: 0.2rem;
  background-color: #fff !important;
  .country-select__indicator,
  .country-select__indicators {
    display: none;
  }
  &.country-select__control--is-focused {
    box-shadow: 0 0 0 0.2rem rgba(182, 208, 246, 255);
  }
}

.coutry-select--is-invalid {
  .country-select__control {
    border: 1px solid #dc3545 !important;
    &.country-select__control--is-focused {
      box-shadow: 0 0 0 0.2rem rgba(234, 193, 200, 255) !important;
    }
  }
}

.country-select__icon {
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: cover;
}

.country-select__single-value {
  color: #262636 !important;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.phone-number-field {
  animation: slideIn 0.5s forwards; /* Adjust duration as needed */
}

.form-control-solid {
  background-color: #f2f2f2 !important;
  &::placeholder {
    color: #d3d3d3 !important;
  }
}

.indicators {
  margin-top: 0px !important;
  padding-bottom: 0.5rem !important;
}

.tab-active {
  color: #5e95e7;
  border-bottom: 3px solid #5e95e7;
}

.ws-nowrap {
  white-space: nowrap;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none !important;
}

.mb-4rem {
  margin-bottom: 4rem;
}
